<template>
  <div class="list3">
    <ul class="box" style="padding-left: 1px; padding-right: 1px;">
      <li v-for="item in list" :key="item.name_jp">
        <div class="item-box">
          <div class="item1">
            {{ item.name_jp }}
          </div>
          <div class="item2"
               :style="{ background: getPresenceBackGroundColor(item.presence_status), color: getPresenceColor(item.presence_status) }"
          >
            {{ getPresenceName(item.presence_status) }}
          </div>
          <div class="item3">
            {{ item.message }}
          </div>
          <div class="item5">
            <v-tooltip left v-if="getArrival(item)">
              <template v-slot:activator="{on, attrs }">
                <v-icon
                    color="green darken-2"
                    dense
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-office-building-outline
                </v-icon>
              </template>
              <span>出社予定</span>
            </v-tooltip>
          </div>
<!--          <div class="item4">-->
<!--            <ElapsedTime :base-time="item.update_time.toDate()"></ElapsedTime>-->
<!--          </div>-->
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment";
// import ElapsedTime from "./ElapsedTime";

export default {
  name: "UserWhiteboard",
  components: {
    // ElapsedTime,
  },
  data: () => ({
    today: ""
  }),
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Array,
      default: () => [],
    },
    presence: {
      type: Array,
      default: () => [],
    }
  },
  created() {
    moment.locale( 'ja' );
    this.today = moment().format("YYYY/MM/DD");
  },
  methods: {
    getArrival(user) {
      if (user.arrival) {
        if (user.arrival.find(element => element === this.today)) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
    getPresenceName(id) {
      for(let val of this.presence) {
        if (val.id.toString() === id.toString()) {
          return val.name;
        }
      }
    },
    getPresenceBackGroundColor(id) {
      for(let val of this.presence) {
        if (val.id.toString() === id.toString()) {
          return val.color;
        }
      }
    },
    getPresenceColor(id) {
      for(let val of this.presence) {
        if (val.id.toString() === id.toString()) {
          return val.font_color;
        }
      }
    }
  }
}
</script>

<style scoped>

.box li {
  list-style: none;
  width: 95%;
  display: inline-block;
  margin-left: 10px;
}

@media screen and (min-width: 768px) {
  .box li {
    list-style: none;
    width: 49%;
    display: inline-block;
    margin-left: 10px;
  }
}

.item-box {
  width: 100%;
  display: inline-block;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 5px;
  border-bottom: 1px solid black;
}

.item1 {
  width: 15%;
  display: inline-block;
  font-size: 12px;
}

.item2 {
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  width: 10%;
  display: inline-block;
}

.item3 {
  vertical-align: center;
  padding-left: 10px;
  width: 60%;
  display: inline-block;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 14px;
}

.item4 {
  padding-left: 10px;
  width: 10%;
  display: inline-block;
  font-size: 12px;
  text-overflow: ellipsis;
}

.item5 {
  padding-left: 10px;
  width: 5%;
  display: inline-block;
  font-size: 12px;
  text-overflow: ellipsis;
}

.v-enter-active, .v-leave-active {
  transition: opacity .3s ease;
}
.v-enter, .v-leave-to {
  opacity: 0;
}

</style>