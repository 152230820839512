<template>
  <div class="list1">
    <v-data-table
      :headers="headers"
      :items="list"
      :items-per-page="25"
      :footer-props="{ 'items-per-page-options': [25,50,100,-1] }"
      :options.sync="options"
    >
      <template v-slot:item.location="{ item }">
        {{ getLocation(item.location) }}
      </template>
      <template v-slot:item.group_id="{ item }">
        {{ getGroupName(item.group_id) }}
      </template>
      <template v-slot:item.presence_status="{ item }">
        <div class="presence_radius" :style="{ background: getPresenceBackGroundColor(item.presence_status), color: getPresenceColor(item.presence_status) }">
          {{ getPresenceName(item.presence_status) }}
        </div>
      </template>
<!--      <template v-slot:item.update_time="{ item }">-->
<!--        {{ getTimestampToFormat(item.update_time.toDate()) }}-->
<!--      </template>-->
    </v-data-table>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "UserCommonList",
  data: () => ({
    options: {},
    headers: [
      {
        text: '社員ID', value: 'employee_id', align: 'center'
      },
      {
        text: '社員名', value: 'name_jp', align: 'center'
      },
      {
        text: 'グループ', value: 'group_id', align: 'center'
      },
      {
        text: '状態', value: 'presence_status', align: 'center'
      },
      {
        text: '場所', value: 'location', align: 'center'
      },
      // {
      //   text: '変更時刻', value: 'update_time', align: 'center'
      // },
    ],
  }),
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Array,
      default: () => [],
    },
    presence: {
      type: Array,
      default: () => [],
    }
  },
  methods: {
    getPresenceName(id) {
      for(let val of this.presence) {
        if (val.id.toString() === id.toString()) {
          return val.name;
        }
      }
    },
    getPresenceBackGroundColor(id) {
      for(let val of this.presence) {
        if (val.id.toString() === id.toString()) {
          return val.color;
        }
      }
    },
    getPresenceColor(id) {
      for(let val of this.presence) {
        if (val.id.toString() === id.toString()) {
          return val.font_color;
        }
      }
    },
    getLocation(location) {
      if (location.toString() === "0") {
        return '自宅';
      } else if (location.toString() === "1") {
        return '出社';
      }
    },
    getGroupName(id) {
      for(let val of this.group) {
        if (val.id.toString() === id.toString()) {
          console.log("" + val.id.toString() + "/" + id.toString());
          return val.name;
        }
      }
    },
    getTimestampToFormat(toDate) {
      const day = dayjs(toDate);
      return day.format('YYYY/MM/DD HH:mm:ss');
    },
  }
}
</script>

<style scoped>

.presence_radius {
  border-radius: 30px;
  text-align: center;
}

</style>