<template>
  <div class="scroll">
    <ArrivalCount :list="list" :group="group" :presence="presence"></ArrivalCount>
    <UserArrivalList :list="list" :group="group" :presence="presence"></UserArrivalList>
  </div>
</template>

<script>

import UserArrivalList from "./UserArrivalList";
import ArrivalCount from "./ArrivalCount";

export default {
  name: "UserArrivalAllList",
  components: {ArrivalCount, UserArrivalList},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Array,
      default: () => [],
    },
    presence: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
  }),
  methods: {
  }
}
</script>

<style scoped>

.scroll table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  border:2px solid #BDBDBD;
}

.scroll td, th {
  padding: 5px 10px;
  border:2px solid #BDBDBD;
  text-align: center;
}

.scroll{
  overflow: auto;
  white-space: nowrap;
  padding-bottom: 10px;
  height: 75vh;
}
.scroll::-webkit-scrollbar{
  height: 5px;
}
.scroll::-webkit-scrollbar-track{
  background: #F1F1F1;
}
.scroll::-webkit-scrollbar-thumb {
  background: #BCBCBC;
}

</style>